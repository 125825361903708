import React from "react";
import Layout from "../components/Layout";

const NotFoundPage = ({ ...props }) => (
  <Layout {...props}>
    <section className="section dark">
      <div className="container tc">
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;
